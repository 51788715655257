import React from "react"
import { Header, MenuItem } from "@life-without-barriers/react-components"

const menuItems: MenuItem[] = [
  {
    kind: "submenu",
    title: "About foster care",
    items: [
      {
        kind: "link",
        title: "About foster care",
        to: "/foster-care/about-foster-care/"
      },
      {
        kind: "link",
        title: "Types of foster care",
        to: "/foster-care/types-of-foster-care/"
      },
      {
        kind: "link",
        title: "About children in care",
        to: "/foster-care/about-children-in-care/"
      },
      {
        kind: "link",
        title: "Aboriginal & Torres Strait Islander children",
        to: "/foster-care/aboriginal-and-torres-strait-islander-children/"
      }
    ]
  },
  {
    kind: "link",
    title: "Can I be a carer?",
    to: "/foster-care/can-i-be-a-carer/"
  },
  {
    kind: "link",
    title: "Stories from care",
    to: "/foster-care/stories/"
  },
  {
    kind: "link",
    title: "Carer support",
    to: "/foster-care/carer-support/"
  },
  {
    kind: "link",
    title: "Contact us",
    to: "/foster-care/contact-us/"
  }
]

const FosterCareHeader = () => (
  <Header menuItems={menuItems} showSecondaryNav={false} />
)

export default FosterCareHeader

import React from "react"
import { Theme, Layout } from "@life-without-barriers/react-components"

import { FosterCareForm } from "@life-without-barriers/shared-contact-form"
import Header from "./Header"
import Footer from "./Footer"
import { defaultPhoneNumber } from "./phoneNumbers"

export interface Props {
  phoneNumber?: string
  contactForm?: React.ReactElement
  children: React.ReactNode
}

const defaultContactForm = (
  <FosterCareForm
    formId="footer-cta"
    formTitle="We’re here to help"
    formSubtitle="Our friendly and helpful foster care specialists are ready to answer your questions. Whether you're looking to start to process or just want more info, our team can help."
    successTitle="We got your enquiry!"
    successMessage="Our team will be in touch within the next business day."
  />
)

const NewFosterCareLayout = ({ children, phoneNumber, contactForm }: Props) => (
  <Layout
    theme={Theme.fosterCareThemeFull}
    header={<Header />}
    footer={<Footer />}
    showSecondaryNav={false}
    fixedCTAPhoneNumber={phoneNumber ?? defaultPhoneNumber}
    fixedCTAContact={contactForm ?? defaultContactForm}
  >
    {children}
  </Layout>
)

export default NewFosterCareLayout

import { Footer, Theme } from "@life-without-barriers/react-components"

import React from "react"
import { defaultPhoneNumber } from "./phoneNumbers"

const email = "carers@lwb.org.au"

const footerLinks = [
  {
    text: "Foster care home",
    url: "/foster-care"
  },
  {
    text: "About foster care",
    url: "/foster-care/about-foster-care"
  },
  {
    text: "Types of foster care",
    url: "/foster-care/types-of-foster-care"
  },
  {
    text: "About children in care",
    url: "/foster-care/about-children-in-care"
  },
  {
    text: "Aboriginal and Torres Strait Islander children",
    url: "/foster-care/aboriginal-and-torres-strait-islander-children"
  },
  {
    text: "Foster care info sessions",
    url: "https://www.eventbrite.com.au/o/life-without-barriers-18159721409"
  },
  {
    text: "Can I be a carer",
    url: "/foster-care/can-i-be-a-carer"
  },
  {
    text: "Stories from care",
    url: "/foster-care/stories"
  },
  {
    text: "Carer support",
    url: "/foster-care/carer-support"
  },
  {
    text: "Contact us",
    url: "/foster-care/contact-us"
  },
  {
    text: "Digital Accessibility Statement",
    url: "/about-us/governance/digital-accessibility-statement"
  }
]

const FosterCareFooter = () => (
  <Footer
    phoneNumber={defaultPhoneNumber}
    emailAddress={email}
    links={footerLinks}
    theme={Theme.fosterCareThemeFull}
  />
)

export default FosterCareFooter

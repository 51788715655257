import {
  Banner,
  Box,
  ContactUsEmailLink,
  ContactUsPhoneLink,
  Container,
  DividedList,
  Heading,
  Helmet,
  IconClipboard,
  IconFamily,
  IconHousing,
  IconPhone,
  IconSchooling,
  IconShake,
  IconTick,
  Link,
  Panel,
  PreStyledComponents,
  Quote,
  ReadMoreLink,
  Row,
  Section,
  SectionBanner,
  SocialMediaLinks,
  TickListItem,
  Variables,
  routesObject,
  AccordionSection
} from "@life-without-barriers/react-components"
import { GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image"
import { Site, getGatsbyImage } from "@life-without-barriers/gatsby-common"

import Layout from "../../../components/foster-care/Layout"
import { FosterCareForm } from "@life-without-barriers/shared-contact-form"
import React from "react"
import { graphql } from "gatsby"
import { oneLine } from "common-tags"

const { LinkButton, IconWrapper } = PreStyledComponents
const { youthPinkXXXLight, youthPinkDark } = Variables

interface Props {
  location: { pathname: string }
  data: {
    site: Site
    couldBeACarer: IGatsbyImageData
    carerAndreaAndColin: IGatsbyImageData
    carerChrisAndSophiaan: IGatsbyImageData
    carerRaechel: IGatsbyImageData
  }
}

interface FosterCarerAttibuteItem {
  title: string
  shortTitle: string
  description: string
}

const carerAttributeItemsData: FosterCarerAttibuteItem[] = [
  {
    title: "I have plenty of time to spend with children",
    shortTitle: "Time to spend with children",
    description: oneLine`
      Children in care need someone who can spend time with them, support them to learn and grow, and help them reach their developmental and educational milestones. Whether you become a foster carer specialising in long- or short-term, emergency, respite/short-break care, you will need to be willing and able to give your time generously to the children in your care. Great Carers realise that investing time in children through everyday moments is key to building healing relationships.
    `
  },
  {
    title: "I want to help children return safely home to their families",
    shortTitle: "Home to their families",
    description: oneLine`
      We know that with their families is the best place for children to be. It’s our responsibility to investigate and support all options for children to return safely home or to explore opportunities for them to live with extended family. As a foster carer, you will play an important role in helping children maintain and strengthen their relationships with their family and return home safely where possible. As a carer you will need to be open to partnering with families to help a child gain a strong sense of who they are, even when they can’t go home.
    `
  },
  {
    title: "I believe in children",
    shortTitle: "Believe in children",
    description: oneLine`
      All children can do well with the right help, care and support, and all children deserve the opportunity to do great things. It’s important that as a foster carer you not only believe in a child’s potential, but that you also actively support each child to reach their goals and aspirations. Championing the unique strengths that each child has, and encouraging them to do great things, even in the small moments of everyday life, is an important part of your role as a carer.
    `
  },
  {
    title: "I want to share my home and my resources",
    shortTitle: "Share my home",
    description: oneLine`
      Every child needs to feel like they are part of a family who loves and supports them. This is especially important when there are other children in the household. Each child needs to know they are special, and to feel included and valued. Foster carers need to have space in their homes – and hearts – to build a sense of belonging for children they care for.
    `
  },
  {
    title: "I want to try and understand each child and their needs",
    shortTitle: "Why children do things",
    description: oneLine`
      Every child in care has their own specific challenges based on their age, life experiences, trauma, diagnosis or medical needs. Great carers are curious and interested, and do all they can to increase their knowledge and skills, in order to meet children where they are at in every moment. This includes learning from children, their families and your Life Without Barriers care team As a carer you will need to be patient, resilient and flexible in responding to the changing needs of children you care for.
    `
  },
  {
    title: "I can help a child manage tough feelings around missing family",
    shortTitle: "Manage tough feelings",
    description: oneLine`
      Most children will be distressed when they are separated from their families, and many yearn to return home. It’s essential that foster carers help kids develop positive, lasting family relationships, to ensure they have a chance to go home, and are able to develop a healthy identity if this doesn’t happen. Children are more secure when they know that their foster carers accept, include and support their own family.
    `
  },
  {
    title: "I believe it is important that children feel included in my family",
    shortTitle: "Good for my family",
    description: oneLine`
      A child in care will benefit from developing relationships with your whole family. Being included in a secure, caring family that functions well is important. If you have children living at home, it’s important they are settled and happy, enjoy their own company, are comfortable sharing your time, are willing to share their space and belongings, and like being around other children. Fostering is something your whole family will be part of and it’s important that everyone is ready.
    `
  }
]

const FosterCarerAttributesSection = () => (
  <Container>
    <AccordionSection
      title={"Becoming a foster carer"}
      paragraph={{
        kind: "inline",
        elements: (
          <div className="mb1">
            So, what’s the process? Start by considering the following
            statements:
          </div>
        )
      }}
      items={carerAttributeItemsData.map((ca) => ({
        heading: ca.title,
        body: { kind: "inline", elements: <p>{ca.description}</p> }
      }))}
    />
  </Container>
)

const CanIBeACarerIndexPage = ({
  location,
  data: {
    couldBeACarer,
    carerAndreaAndColin,
    carerChrisAndSophiaan,
    carerRaechel,
    site: { siteMetadata }
  }
}: Props) => {
  return (
    <div>
      <Helmet
        title={`Can I become a foster carer? | ${siteMetadata.title}`}
        description={
          "Single. Married. Gay. Straight. Young. Senior. If you have a desire to provide a safe and caring environment for a child, then you can become a foster carer."
        }
        image={couldBeACarer}
        siteUrl={siteMetadata.siteUrl}
        path={location.pathname}
      />
      <Layout>
        <Banner
          title="Can I become a foster carer?"
          breadcrumbs={[routesObject.fosterCareHome]}
        />
        <Section background="bg-lwb-grey-xxx-light">
          <Container>
            <Row>
              <Box className="layout-readable">
                <Heading size={2}>
                  Single. Partnered. Gay. Straight. Young. Senior.
                </Heading>
                <p>
                  If you have a desire, and the capacity, to provide a safe,
                  respectful, caring environment for children, and want nothing
                  more than to welcome children into your home and life, you can
                  become a foster carer.
                </p>
                <p>
                  While there are some boxes that need to be ticked, the most
                  valuable attributes you will bring is not the size of your
                  home, the length of your CV, or your relationship status.
                </p>
                <p>
                  Life Without Barriers wants foster carers who will bring
                  security, stability, respect and joy into children’s lives.
                </p>
                <p>It’s that simple.</p>
              </Box>
            </Row>
            <Row className="flex-wrap">
              <Box className="w-100 w-50-m w-third-l mt4">
                <Panel background="bg-lwb-white">
                  <GatsbyImage
                    image={getGatsbyImage(carerAndreaAndColin)}
                    alt=""
                    className="mt4 mt0-ns"
                  />
                  <Quote size="small">
                    &ldquo;We&apos;re only in our 50s so we&apos;ve got plenty
                    of life to live and love to give.&rdquo;
                  </Quote>
                  <p>
                    <Link
                      className="black"
                      to="/foster-care/stories/andrea-and-colin/"
                    >
                      Colin and Andrea
                    </Link>
                    , married with kids, caring for children with complex needs.
                  </p>
                </Panel>
              </Box>
              <Box className="w-100 w-50-m w-third-l mt4 dn db-ns">
                <Panel background="bg-lwb-white">
                  <GatsbyImage
                    image={getGatsbyImage(carerChrisAndSophiaan)}
                    alt=""
                    className="mt4 mt0-ns"
                  />
                  <Quote size="small">
                    &ldquo;While we were doing [Life Without Barriers] training
                    we met with other same-sex couples - so we&apos;re not the
                    only gays in the village!&rdquo;
                  </Quote>
                  <p>
                    <Link
                      className="black"
                      to="/foster-care/stories/chris-and-sophiaan/"
                    >
                      Sophiaan and Chris
                    </Link>
                    , married, no kids, providing long-term care for children
                    with trauma and behavioural challenges.
                  </p>
                </Panel>
              </Box>
              <Box className="w-100 w-50-m w-third-l mt4 dn db-l">
                <Panel background="bg-lwb-white">
                  <GatsbyImage
                    image={getGatsbyImage(carerRaechel)}
                    alt=""
                    className="mt4 mt0-ns"
                  />
                  <Quote size="small">
                    &ldquo;I&apos;ve often been asked how as a single woman
                    I&apos;ve been &apos;allowed&apos; to be a carer. Respite
                    care can be [provided by] a single male or female, and busy
                    professionals can contribute, too. Don&apos;t let anyone
                    tell you otherwise.&rdquo;
                  </Quote>
                  <p>
                    <Link className="black" to="/foster-care/stories/raechel/">
                      Raechel
                    </Link>
                    , single, no kids, provides regular respite care.
                  </p>
                </Panel>
              </Box>
            </Row>
          </Container>
        </Section>
        <Container>
          <Row>
            <Box className="layout-readable">
              <Heading size={2}>
                Aboriginal & Torres Strait Islander foster carers
              </Heading>
              <p>
                To ensure children maintain connections with their country, kin
                and culture, we need Aboriginal & Torres Strait Islander foster
                carers for Aboriginal & Torres Strait Islander kids.
              </p>
              <ReadMoreLink
                className="mt4"
                text="Find out more"
                href="/foster-care/aboriginal-and-torres-strait-islander-children/"
              />
            </Box>
          </Row>
        </Container>
        <SectionBanner alignRight={true} backgroundImage={couldBeACarer}>
          <Heading size={2}>Could you be a carer?</Heading>
          <p>
            Take the foster care self-assessment. It only takes minutes to find
            out if foster care is something you could do.
          </p>
          <LinkButton
            to="/foster-care/can-i-be-a-carer/self-assessment/"
            title="Take the quiz"
            className="mt4 mr2-ns tc fw8"
            state={{
              returnUrl: "/foster-care/can-i-be-a-carer/"
            }}
          >
            Take the quiz
          </LinkButton>
        </SectionBanner>
        <FosterCarerAttributesSection />
        <Section background="bg-lwb-grey-xxx-light">
          <Container>
            <Row>
              <Box>
                <Heading size={2}>Carer checklist</Heading>
              </Box>
            </Row>
            <Row className="flex-wrap">
              <Box className="w-100 w-50-ns mt4">
                <Panel background="bg-lwb-white">
                  <Heading size={3} topMargin="none">
                    About you
                  </Heading>
                  <ul className="mt4 pl1">
                    {[
                      "Over 18 with relevant life experience (In some states and territories you may need to be over 21 or 25)",
                      "From any culture, ethnicity, faith, sexuality or gender identity/expression",
                      "Well and healthy ",
                      "Happy to undergo background checks"
                    ].map((text, i) => (
                      <TickListItem
                        key={i}
                        text={text}
                        className={i === 0 ? "w-90-ns" : "mt3 w-90-ns"}
                        textClasses="pt0 pt1-ns"
                      />
                    ))}
                  </ul>
                </Panel>
              </Box>
              <Box className="w-100 w-50-ns mt4">
                <Panel background="bg-lwb-white">
                  <Heading size={3} topMargin="none">
                    About your home and family
                  </Heading>
                  <ul className="mt4 pl1">
                    {[
                      "With or without children",
                      "Have a stable home with available space ",
                      "Everyone active and willing to be involved with fostering",
                      "Happy to undergo background checks"
                    ].map((text, i) => (
                      <TickListItem
                        key={i}
                        text={text}
                        className={i === 0 ? "w-90-ns" : "mt3 w-90-ns"}
                        textClasses="pt0 pt1-ns"
                      />
                    ))}
                  </ul>
                </Panel>
              </Box>
            </Row>
          </Container>
        </Section>
        <Container>
          <Row>
            <Box className="layout-readable">
              <Heading size={2}>
                I&apos;m ready. What&apos;s the process for becoming a foster
                carer?
              </Heading>
              <p>
                Applications to become a foster Carer take up to 12 months, but
                don&apos;t let that put you off. We make sure Foster Carers have
                the right training and support in place. At Life Without
                Barriers, we also take time matching the right foster carer with
                the right child or young person.
              </p>
              <p>
                If you want to understand more about foster care and the
                application process, you can attend an information session
                either online or in person in your state.
              </p>

              <ReadMoreLink
                className="mt4"
                text="Attend a foster care information session"
                href="https://www.eventbrite.com.au/o/life-without-barriers-18159721409"
              />
            </Box>
          </Row>
          <Row>
            <Box className="layout-readable">
              <DividedList>
                <>
                  <li className="pv3 pv4-ns">
                    <Row className="flex-wrap pv3 mt5">
                      <Box className="w-100 w-30-ns flex-shrink-0 tc pt3 pt0-ns">
                        <IconWrapper size="125" color={youthPinkXXXLight}>
                          <IconTick
                            height="58"
                            width="58"
                            color={youthPinkDark}
                          />
                        </IconWrapper>
                      </Box>
                      <Box className="w-100 w-70-ns pt2 pt0-ns pl3-ns pr3-m pr5-l tl">
                        <Heading size={3} topMargin="none">
                          Step 1: Make an enquiry
                        </Heading>
                        <p>
                          You can speak to one of our specialist team by
                          contacting us on 1300 592 227 or at carers@lwb.org.au.
                          Alternatively, you can submit the online form below
                          and one of our team will be in touch.
                        </p>
                        <div className="mt2 flex flex-row flex-wrap w-70-ns">
                          <ContactUsPhoneLink
                            marginClasses="w-100 w-50-l mv2"
                            phoneNumber="1300 592 227"
                          />
                          <ContactUsEmailLink
                            marginClasses="w-100 w-50-l mv2"
                            text="carers@lwb.org.au"
                            link="mailto:carers@lwb.org.au"
                          />
                        </div>
                      </Box>
                    </Row>
                  </li>
                  <li className="pv3 pv4-ns">
                    <Row className="flex-wrap">
                      <Box className="w-100 w-30-ns flex-shrink-0 tc pt3 pt0-ns">
                        <IconWrapper size="125" color={youthPinkXXXLight}>
                          <IconPhone
                            height="58"
                            width="58"
                            color={youthPinkDark}
                          />
                        </IconWrapper>
                      </Box>
                      <Box className="w-100 w-70-ns pt2 pt0-ns pl3-ns pr3-m pr5-l tl">
                        <Heading size={3} topMargin="none">
                          Step 2: Let’s talk
                        </Heading>
                        <p>
                          One of our team will call to talk you through the
                          process in more detail. During this time, you will
                          learn more about the needs of children in care and the
                          role of a foster carer. This will help you decide if
                          fostering is right for you and if now is the right
                          time. If you feel ready to progress further at this
                          stage, we will organise a phone interview to chat more
                          about your suitability and how fostering might work
                          for you.
                        </p>
                      </Box>
                    </Row>
                  </li>
                  <li className="pv3 pv4-ns">
                    <Row className="flex-wrap">
                      <Box className="w-100 w-30-ns flex-shrink-0 tc pt3 pt0-ns">
                        <IconWrapper size="125" color={youthPinkXXXLight}>
                          <IconHousing
                            height="58"
                            width="58"
                            color={youthPinkDark}
                          />
                        </IconWrapper>
                      </Box>
                      <Box className="w-100 w-70-ns pt2 pt0-ns pl3-ns pr3-m pr5-l tl">
                        <Heading size={3} topMargin="none">
                          Step 3: Home Visit
                        </Heading>
                        <p>
                          We’ll link you with your local carer team who will
                          organise a home visit or organise a group information
                          session for you to attend. During the home visit, we
                          make sure you can provide a safe place for a child or
                          young person to stay and provide you with some
                          paperwork to take the next steps.
                        </p>
                      </Box>
                    </Row>
                  </li>
                  <li className="pv3 pv4-ns">
                    <Row className="flex-wrap">
                      <Box className="w-100 w-30-ns flex-shrink-0 tc pt3 pt0-ns">
                        <IconWrapper size="125" color={youthPinkXXXLight}>
                          <IconSchooling
                            height="58"
                            width="58"
                            color={youthPinkDark}
                          />
                        </IconWrapper>
                      </Box>
                      <Box className="w-100 w-70-ns pt2 pt0-ns pl3-ns pr3-m pr5-l tl">
                        <Heading size={3} topMargin="none">
                          Step 4: Training
                        </Heading>
                        <p>
                          If you chose to apply, you will participate in a
                          workshop-style training program to help you understand
                          and prepare for your role as a foster carer. These
                          programs are a great opportunity for you to meet other
                          applicants and experienced carers, and learn from
                          their experiences.
                        </p>
                      </Box>
                    </Row>
                  </li>
                  <li className="pv3 pv4-ns">
                    <Row className="flex-wrap">
                      <Box className="w-100 w-30-ns flex-shrink-0 tc pt3 pt0-ns">
                        <IconWrapper size="125" color={youthPinkXXXLight}>
                          <IconClipboard
                            height="58"
                            width="58"
                            color={youthPinkDark}
                          />
                        </IconWrapper>
                      </Box>
                      <Box className="w-100 w-70-ns pt2 pt0-ns pl3-ns pr3-m pr5-l tl">
                        <Heading size={3} topMargin="none">
                          Step 5: Assessment
                        </Heading>
                        <p>
                          During the carer assessment process, an assessor will
                          visit you several times in your home and interview all
                          members of your family to ensure fostering is a good
                          fit for everyone. We’ll explore your life experiences,
                          and the important carer attributes to ensure children
                          in care have a safe and supportive environment.
                        </p>
                      </Box>
                    </Row>
                  </li>
                  <li className="pv3 pv4-ns">
                    <Row className="flex-wrap">
                      <Box className="w-100 w-30-ns flex-shrink-0 tc pt3 pt0-ns">
                        <IconWrapper size="125" color={youthPinkXXXLight}>
                          <IconFamily
                            height="58"
                            width="58"
                            color={youthPinkDark}
                          />
                        </IconWrapper>
                      </Box>
                      <Box className="w-100 w-70-ns pt2 pt0-ns pl3-ns pr3-m pr5-l tl">
                        <Heading size={3} topMargin="none">
                          Step 6: Approval
                        </Heading>
                        <p>
                          If the assessment shows you are a good fit for foster
                          care, you will become an approved foster carer.
                          Together with our assessor, recommendations will be
                          made about the types of care, needs of children and
                          number of children you’d be best placed to care for.
                          This will help us make a good match with children
                          needing care.
                        </p>
                      </Box>
                    </Row>
                  </li>
                  <li className="pv3 pv4-ns">
                    <Row className="flex-wrap">
                      <Box className="w-100 w-30-ns flex-shrink-0 tc pt3 pt0-ns">
                        <IconWrapper size="125" color={youthPinkXXXLight}>
                          <IconShake
                            height="58"
                            width="58"
                            color={youthPinkDark}
                          />
                        </IconWrapper>
                      </Box>
                      <Box className="w-100 w-70-ns pt2 pt0-ns pl3-ns pr3-m pr5-l tl">
                        <Heading size={3} topMargin="none">
                          Congratulations!
                        </Heading>
                        <p>
                          Our care teams will look after you with extra
                          training, and connect you with our Caring Community
                          whilst we look to make a good match with children
                          needing your care. Your journey with us doesn’t end
                          there. Life Without Barriers are with you the whole
                          way and you will receive ongoing support and training
                          from our team over the whole of your caring journey.
                        </p>
                      </Box>
                    </Row>
                  </li>
                </>
              </DividedList>
            </Box>
          </Row>
        </Container>
        <Section background="bg-lwb-theme-xxx-light">
          <Container>
            <Row className="flex-wrap justify-between-l">
              <Box className="w-100 w-50-l">
                <Heading size={2}>
                  Keen to learn more? Get in touch today.
                </Heading>
                <div className="mt2 flex flex-row flex-wrap pv3">
                  <ContactUsPhoneLink
                    marginClasses="w-100 w-50-l mv2"
                    phoneNumber="1300 592 227"
                    styles={{ iconBackgroundColor: "#fff" }}
                  />
                  <ContactUsEmailLink
                    marginClasses="w-100 w-50-l mv2"
                    text="carers@lwb.org.au"
                    link="mailto:carers@lwb.org.au"
                    styles={{ iconBackgroundColor: "#FFF" }}
                  />
                </div>
                <p>
                  We’re sure you have questions, and our team is waiting to
                  answer them. You can contact us today and we will come back to
                  you as soon as possible.
                </p>
                <div className={"pt4"}>
                  <SocialMediaLinks styles={{ iconBackgroundColor: "#FFF" }} />
                </div>
              </Box>
              <Box className="w-100 w-third-l mt4 mt0-l">
                <FosterCareForm
                  formId="can-i-be-a-carer"
                  formTitle="Talk to a specialist"
                />
              </Box>
            </Row>
          </Container>
        </Section>
      </Layout>
    </div>
  )
}

export const query = graphql`
  query {
    couldBeACarer: file(relativePath: { regex: "/lwb-learning-to-ride.jpg/" }) {
      childImageSharp {
        gatsbyImageData(quality: 90, width: 1200)
      }
    }
    carerAndreaAndColin: file(
      relativePath: { regex: "/andrea-and-colin.jpg/" }
    ) {
      childImageSharp {
        gatsbyImageData(quality: 90, width: 400)
      }
    }
    carerChrisAndSophiaan: file(
      relativePath: { regex: "/chris-and-sophiaan-page.jpg/" }
    ) {
      childImageSharp {
        gatsbyImageData(quality: 90, width: 400)
      }
    }
    carerRaechel: file(relativePath: { regex: "/raechel-page.jpg/" }) {
      childImageSharp {
        gatsbyImageData(quality: 90, width: 400)
      }
    }
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
  }
`

export default CanIBeACarerIndexPage
